'use client'

import Cookies from 'js-cookie'
import { buildUrlWithQueryParams } from './buildUrlWithQueryParams'
import { FetchOptions } from './FetchOptions'

const fetchClient = (
  { url, query, method, data }: FetchOptions,
  localApi?: boolean
) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/vnd.api+json')
  headers.append('Accept', 'application/vnd.api+json')

  const auth = localStorage.getItem('auth')

  const {
    accessToken,
    currentUserId,
  }: {
    accessToken: string
    currentUserId: string
  } = JSON.parse(auth || '{}')

  const queryParams = {
    access_token: accessToken,
    current_user_id: currentUserId,
    ...query,
  }

  const urlWithQueryParams = localApi
    ? url
    : buildUrlWithQueryParams(`https://api.codeverse.com${url}`, queryParams)

  // console.log('fetching from', urlWithQueryParams)

  return fetch(urlWithQueryParams, {
    headers: headers,
    method,
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json()
  })
}

export default fetchClient
