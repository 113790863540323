'use client'
import React, { useEffect, useContext, useState } from 'react'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

// import { useResponsive } from '../../../hooks/useResponsive'

const modal = {
  base: 'w-full overflow-hidden opacity-1 rounded-4xl sm:rounded-3xl sm:m-1 sm:max-w-[416px] z-2000',
}
export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Function executed when the dropdown is closed
   */
  onClose: (event: any) => void
  /**
   * Defines if the modal is open
   */
  isOpen: boolean
  width?: string | number
  height?: string | number
  overlayClass?: string
  overlayPosition?: string
  bgClass?: string
  bgImg?: string
  marginTop?: string
  contentContainerClassname?: string
  contentClassname?: string
  containerClassname?: string
  icon?: any
  iconBackgroundColor?: string
  allowEsc?: boolean
  showOverflow?: boolean
  hideClose?: boolean
  bottomElement?: React.ReactNode
  id?: string
  contentStyles?: any
  confetti?: any
}

const Modal: React.FC<Props> = function Modal(props, ref) {
  const {
    children,
    onClose,
    isOpen,
    confetti,
    width = 'auto',
    height = 'auto',
    overlayClass = 'bg-[#101432] backdrop-blur-2xl',
    overlayPosition = 'absolute',
    bgClass = 'bg-white',
    bgImg = 'none',
    marginTop = '0px',
    contentContainerClassname,
    contentClassname,
    containerClassname,
    contentStyles,
    icon,
    iconBackgroundColor,
    bottomElement,
    allowEsc = false,
    showOverflow,
    hideClose,
    id = '',
    ...other
  } = props

  // const { isMobile, isTablet } = useResponsive()

  const baseStyle = modal.base

  function handleEsc(e: KeyboardEvent) {
    // e.stopPropagation()
    if (e.key === 'Esc' || e.key === 'Escape') {
      onClose(e)
    }
  }

  useEffect(() => {
    if (allowEsc && isOpen) {
      document.addEventListener('keydown', handleEsc, { capture: true })
      return () => {
        document.removeEventListener('keydown', handleEsc)
      }
    }
  }, [allowEsc, isOpen])

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const containerClassnames = classNames(
    'flex flex-grow h-full items-end justify-center opacity-0 z-2000 lg:items-center 2xl:items-center',
    {
      'flex-col': bottomElement,
    },
    containerClassname
  )

  const contentContainerClassnames = classNames(
    'flex-row relative z-200',
    contentContainerClassname
  )

  const contentClassnames = classNames(
    'font-poppins-medium h-full relative rounded-t-4xl z-200 lg:rounded-4xl',
    bgClass,
    contentClassname,
    {
      'pt-[86px]': icon,
      'overflow-hidden': !icon && !showOverflow,
      'overflow-visible': !icon && showOverflow,
    }
  )
  const iconContainerClassnames = classNames(
    '-translate-x-1/2 -translate-y-1/2 absolute bg-white h-[160px] left-1/2 opacity-100 rounded-full top-0 w-[160px] z-200'
  )
  const innerIconContainerClassnames = classNames(
    'absolute flex inset-2 items-center justify-center rounded-full',
    {
      //@ts-ignore
      [iconBackgroundColor]: !!iconBackgroundColor,
      'bg-white': !iconBackgroundColor,
    }
  )
  const modalComponent = (
    <>
      {isOpen && (
        <motion.div
          id={id}
          initial={{
            display: 'hidden',
          }}
          animate={{
            display: 'block',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          exit={{ display: 'hidden' }}
          className="z-[9999]"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 0.8,
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={(e) => {
              onClose(e)
            }}
            className={
              'overlay inset-0 opacity-0 ' +
              overlayClass +
              ' ' +
              overlayPosition
            }
          />
          {confetti && confetti()}
          {!hideClose && (
            <motion.div
              whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="absolute right-4 top-[52px] z-[9999] flex h-11 w-12 cursor-pointer items-center justify-center rounded-full bg-[#292E50] bg-opacity-50 text-white md:right-6 md:top-4 lg:right-6 lg:top-4"
              onClick={(e) => {
                e.stopPropagation()
                onClose(e)
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </motion.div>
          )}
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.4, type: 'tween' }}
            className={containerClassnames}
            onClick={(e) => {
              if (allowEsc) {
                onClose(e)
              }
            }}
          >
            <div
              className={contentContainerClassnames}
              onClick={(e) => e.stopPropagation()}
              style={{
                width: width,
                height: height,
                ...contentStyles,
              }}
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.4, type: 'tween' }}
                style={{
                  height: '100%',
                  // maxWidth: '760px',
                  backgroundImage: bgImg,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '50%',
                  marginTop: marginTop,
                }}
                className={contentClassnames}
                //onClick={() => { if(allowEsc){ onClose() }}}
              >
                {icon && (
                  <div className={iconContainerClassnames}>
                    <div className={innerIconContainerClassnames}>
                      <FontAwesomeIcon
                        className="text-white"
                        icon={icon}
                        size="4x"
                      />
                    </div>
                  </div>
                )}
                {children}
              </motion.div>
            </div>
            {/* @ts-ignore */}
            {typeof bottomElement === 'function' && bottomElement()}
          </motion.div>
        </motion.div>
      )}
    </>
  )
  return modalComponent
  // return mounted ? createPortal(modalComponent, document.body) : null
}

export default Modal
