import { useQuery } from '@tanstack/react-query'
import { MissionStep } from 'apps/studio/types/MissionStep'
import fetchClient from 'apps/studio-shared/src/Data/fetch/fetchClient'
import { SatelliteClient } from '@codeverse/satellite-client'
import Cookies from 'js-cookie'

export const getMissionSteps = (missionId: string) => {
  return fetchClient({
    url: `/missions/${missionId}/mission_steps`,
    method: 'GET',
  })
}
export const getMissionStepTips = (missionStepId: string) => {
  return SatelliteClient.platform.get(
    `/mission_steps/${missionStepId}/mission_step_tips`,
    {
      headers: Object.assign(
        {},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' }
      ),
    }
  )
}

export const deleteMissionStepTip = (missionStepTipId: string) => {
  const auth = localStorage.getItem('auth')
  const { currentUserId } = JSON.parse(auth || '{}')
  return SatelliteClient.platform.delete(
    `/mission_step_tips/${missionStepTipId}`,
    {
      headers: Object.assign(
        {},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' }
      ),
      query: {
        current_user_id: currentUserId,
      },
    }
  )
}

export const getMissionStep = (missionStepId: string) => {
  return SatelliteClient.platform.get(`/mission_steps/${missionStepId}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
  })
}

export const createMissionStepTip = (MissionStepTipCreateParams: any) => {
  const { missionStepId, ...rest } = MissionStepTipCreateParams
  return SatelliteClient.platform.post(`/mission_step_tips`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      ...rest,
      relationships: {
        mission_step: {
          id: missionStepId,
          type: 'mission_steps',
        },
      },
    },
    serializer: 'mission_step_tips',
  })
}

export const createMission = (MissionCreateParams: any) => {
  return SatelliteClient.platform.post(`/missions`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      ...MissionCreateParams,
    },
    serializer: 'missions',
  })
}

export const createMissionStep = (MissionStepCreateParams: any) => {
  const { missionId, ...rest } = MissionStepCreateParams
  return SatelliteClient.platform.post(`/mission_steps`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      ...rest,
      relationships: {
        mission: {
          id: missionId,
          type: 'missions',
        },
      },
    },
    serializer: 'mission_steps',
  })
}

export const deleteMissionStep = (missionStepId: string) => {
  const auth = localStorage.getItem('auth')
  const { currentUserId } = JSON.parse(auth || '{}')
  return SatelliteClient.platform.delete(`/mission_steps/${missionStepId}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    query: {
      current_user_id: currentUserId,
    },
  })
}

export const updateMissionStep = (missionStepId: string, data: any) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.put(`/mission_steps/${missionStepId}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    query: {
      current_user_id: currentUserId,
    },
    data: {
      ...data,
    },
    serializer: 'mission_steps',
  })
}

export const updateMissionStepTip = ({ missionStepTipId, data }: any) => {
  const auth = localStorage.getItem('auth')
  const { currentUserId } = JSON.parse(auth || '{}')
  return SatelliteClient.platform.put(
    `/mission_step_tips/${missionStepTipId}`,
    {
      headers: Object.assign(
        {},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' }
      ),
      query: {
        current_user_id: currentUserId,
      },
      data: {
        id: missionStepTipId,
        ...data,
      },
      serializer: 'mission_step_tips',
    }
  )
}

export const updateMission = (MissionUpdateParams: any) => {
  return SatelliteClient.platform.put(`/missions/${MissionUpdateParams.id}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      ...MissionUpdateParams,
    },
    serializer: 'missions',
  })
}

export const getMissionStepInsertables = (missionStepId: string) => {
  return SatelliteClient.platform.get(
    `/mission_steps/${missionStepId}/mission_step_insertables`
  )
}

export const deleteMissionStepInsertable = (
  missionStepInsertableId: string
) => {
  return SatelliteClient.platform.delete(
    `/mission_step_insertables/${missionStepInsertableId}`
  )
}

export const getMissionStepsMultipleChoice = (missionStepId: string) => {
  const auth = localStorage.getItem('auth')
  const { currentUserId } = JSON.parse(auth || '{}')
  return SatelliteClient.platform.get(
    `/mission_steps/${missionStepId}/multiple_choices`,
    {
      headers: Object.assign(
        {},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' }
      ),
      query: {
        current_user_id: currentUserId,
      },
    }
  )
}

export const createMultipleChoiceAnswer = (
  multiple_choice_id: string,
  answer: string
) => {
  const auth = localStorage.getItem('auth')
  const { currentUserId } = JSON.parse(auth || '{}')
  const organizationId = localStorage.getItem('currentOrganizationId')
  return SatelliteClient.platform.post('/multiple_choice_answers', {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      answer,
      relationships: {
        multiple_choice: {
          id: multiple_choice_id,
          type: 'multiple_choices',
        },
      },
    },
    query: {
      current_user_id: currentUserId,
      current_organization_id: organizationId,
    },
    serializer: 'multiple_choice_answers',
  })
}

export const createMultipleChoice = (
  { question, answer_a, answer_b, answer_c, answer_d, position, answer },
  missionStepId
) => {
  const auth = localStorage.getItem('auth')
  const { currentUserId } = JSON.parse(auth || '{}')
  const organizationId = localStorage.getItem('currentOrganizationId')
  return SatelliteClient.platform.post('/multiple_choices', {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      question,
      answer_a,
      answer_b,
      answer_c,
      answer_d,
      position,
      answer,
      relationships: {
        mission_step: {
          id: missionStepId,
          type: 'mission_steps',
        },
      },
    },
    serializer: 'multiple_choices',
  })
}

export const updateMultipleChoice = (
  { question, answer_a, answer_b, answer_c, answer_d, position, answer },
  missionStepId
) => {
  const auth = localStorage.getItem('auth')
  const { currentUserId } = JSON.parse(auth || '{}')
  const organizationId = localStorage.getItem('currentOrganizationId')
  return SatelliteClient.platform.put(`/multiple_choices/${missionStepId}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      question,
      answer_a,
      answer_b,
      answer_c,
      answer_d,
      position,
      answer,
      id: missionStepId,
    },
    serializer: 'multiple_choices',
  })
}

export const createMissionStepInsertable = (
  missionStepId: string,
  data: any
) => {
  const auth = localStorage.getItem('auth')
  const { currentUserId } = JSON.parse(auth || '{}')
  return SatelliteClient.platform.post(`/mission_step_insertables`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    query: {
      current_user_id: currentUserId,
    },
    data: {
      ...data,
      relationships: {
        mission_step: {
          id: missionStepId,
          type: 'mission_steps',
        },
      },
    },
    serializer: 'mission_step_insertables',
  })
}

export const getMissionAchievements = () => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.get(
    `/users/${currentUserId}/mission_achievements`,
    {
      headers: Object.assign(
        {},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' }
      ),
      query: {
        current_user_id: currentUserId,
      },
    }
  )
}

export const getMissionAchievement = (missionAchievementId: string) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.get(
    `/mission_achievements/${missionAchievementId}`,
    {
      headers: Object.assign(
        {},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' }
      ),
      query: {
        current_user_id: currentUserId,
      },
    }
  )
}
