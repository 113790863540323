import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createMission,
  createMissionStep,
  createMissionStepInsertable,
  createMissionStepTip,
  deleteMissionStepTip,
  createMultipleChoice,
  createMultipleChoiceAnswer,
  deleteMissionStep,
  deleteMissionStepInsertable,
  updateMission,
  updateMissionStep,
  updateMultipleChoice,
  updateMissionStepTip,
} from './MissionFetch'
import { SatelliteClient } from '@codeverse/satellite-client'

export const useCreateMissionStepTipMutation = () => {
  const mutation = useMutation({
    mutationFn: ({ missionStepId, content }: any) =>
      createMissionStepTip({ missionStepId, content }),
  })
  return mutation
}

export const useCreateMissionMutation = () => {
  const mutation = useMutation({
    mutationFn: (data: any) => createMission(data),
  })
  return mutation
}

export const useUpdateMissionMutation = () => {
  const mutation = useMutation({
    mutationFn: (data: any) => updateMission(data),
  })
  return mutation
}

export const useMutateCreateMissionStep = () => {
  const mutation = useMutation({
    mutationFn: (data) => createMissionStep(data),
  })
  return mutation
}

export const useUpdateMissionStepMutation = () => {
  const mutation = useMutation({
    mutationFn: (data: any) => {
      const { id: missionStepId } = data
      return updateMissionStep(missionStepId, data)
    },
  })
  return mutation
}

export const useUpdateMissionStepTip = () => {
  const mutation = useMutation({
    mutationFn: ({ missionStepTipId, data }: any) =>
      updateMissionStepTip({ missionStepTipId, data: data }),
  })
  return mutation
}

export const useDeleteMissionStepTip = () => {
  const mutation = useMutation({
    mutationFn: (missionStepTipId: string) =>
      deleteMissionStepTip(missionStepTipId),
  })
  return mutation
}

export const useMutateMultipleChoiceAnswer = () => {
  const mutation = useMutation({
    mutationFn: ({ multiple_choice_id, answer }: any) => {
      return createMultipleChoiceAnswer(multiple_choice_id, answer)
    },
  })
  return mutation
}

export const useMutateCreateMultipleChoice = () => {
  const mutation = useMutation({
    mutationFn: ({ id: missionStepId, ...rest }: any) =>
      createMultipleChoice(rest, missionStepId),
  })
  return mutation
}

export const useMutateUpdateMultipleChoice = () => {
  const mutation = useMutation({
    mutationFn: ({ id: missionStepId, ...rest }: any) =>
      updateMultipleChoice(rest, missionStepId),
  })
  return mutation
}

export const useCreateMissionStepInsertable = () => {
  const mutation = useMutation({
    mutationFn: ({ missionStepId, ...rest }: any) =>
      createMissionStepInsertable(missionStepId, rest),
  })
  return mutation
}

export const useCreateMissionStepTip = () => {
  const mutation = useMutation({
    mutationFn: (data: any) => createMissionStepTip(data),
  })
  return mutation
}

export const useDeleteMissionStep = () => {
  const mutation = useMutation({
    mutationFn: (missionStepId: string) => deleteMissionStep(missionStepId),
  })
  return mutation
}

export const useDeleteMissionStepInsertable = () => {
  return useMutation({
    mutationFn: (missionStepInsertableId: string) =>
      deleteMissionStepInsertable(missionStepInsertableId),
  })
}

export const updateMissionAchievementStep = (
  missionAchievementId: string,
  missionStepId: string,
  data: any
) => {
  return SatelliteClient.platform.put(
    `/mission_achievements/${missionAchievementId}`,
    {
      headers: Object.assign(
        {},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' }
      ),
      data: {
        id: missionAchievementId,
        ...data,
        relationships: {
          mission_step: {
            id: missionStepId,
            type: 'mission_steps',
          },
        },
      },
      serializer: 'mission_achievements',
    }
  )
}

export const useMutateMissionAchievement = () => {
  const mutation = useMutation({
    mutationFn: ({ missionAchievementId, missionStepId, data }: any) => {
      return updateMissionAchievementStep(
        missionAchievementId,
        missionStepId,
        data
      )
    },
  })
  return mutation
}
