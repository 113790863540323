// This function will take a base URL and a dictionary of query parameters and
// will return a URL object with the base URL and the query parameters appended
// to it.

import { isEmpty } from 'lodash'
import qs from 'qs'

export const removeEmptyAttributes = (obj) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') removeEmptyAttributes(val)
    else if (val === null || val === undefined || val === 'undefined') {
      delete obj[key]
    }
  })
  return obj
}

export const requestURLWithQuery = (query) => {
  if (!isEmpty(query)) {
    const cleanQuery = removeEmptyAttributes(query)
    return `${qs.stringify(cleanQuery)}`
  }
  return ''
}

export function buildUrlWithQueryParams(
  baseUrl: string,
  queryParams: Record<string, string>
): URL {
  const url = new URL(baseUrl)
  url.search = requestURLWithQuery(queryParams)
  return url
}
